@font-face {
  font-family: "Nunito-Regular";
  src: url(fonts/Nunito-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito-Light";
  src: url(fonts/Nunito-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito-Bold";
  src: url(fonts/Nunito-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito-ExtraBold";
  src: url(fonts/Nunito-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito-Italic";
  src: url(fonts/Nunito-Italic.ttf) format("truetype");
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 16px;
}
#root {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: "Nunito-Regular";
  font-size: 0.78rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #12162b;
  color: white;
}

em {
  font-style: normal;
}
