.container {
  width: 100%;
}

.slick-list {
  padding: 0 20% 0 0 !important;
}

.slick-slide {
  margin-right: 15px;
}

.slick-slide > div {
  width: 100%;
  height: 100%;
  position: relative;
}

.slick-slide img {
  margin: auto;
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.slick-disabled {
  display: none !important;
}

.slick-next:before {
  display: none;
}
.slick-prev:before {
  display: none;
}

.slick-prev,
.slick-next {
  z-index: 1;
  width: 37px;
  height: 37px;
}

@media screen and (max-width: 3841px) and (min-width: 2561px) {
  .slick-slide {
    margin-right: 25px;
  }
}

/*  @media screen and (max-width: 1367px) and (min-width: 1025px) {
  /*  
     Try to comment this out:
     it seems useless in Groove, while breaking other 
     slick implementations in the applicatrion
  */
/* .slick-slide {
    width: 270px;
    margin-right: 15px;
  } 
}*/

@media screen and (max-width: 1025px) {
  .slick-slide {
    width: 180px;
    margin-right: 15px;
  }

  .slick-next {
    width: 5%;
  }
}
